import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: "#120f21",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    margin: 0,
    fontSize: 40,
    maxHeight: '100vh',
    borderRadius: 30,
    textAlign: 'center',
    color: '#edbe1e',
    fontFamily: "AbrilFatfacefont"
  }));

export default StyledPaper;