import React, { useEffect, useState } from "react";


import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container, Typography } from '@mui/material';
import { Slideshow } from "./Slideshow";
import Countdown from "./Countdown";
import moment from 'moment';
import StyledPaper from "./StyledPaper";
import { useCountdown } from "./useCountdown";

const theDate = moment('2022-09-26 00:00:00');

function App() {

    const isTheDate = moment().diff(theDate) > 0;
    const theDateAsDate = theDate.toDate();

    useCountdown(theDateAsDate);

    console.log('isTheDate', isTheDate);

    return (
        <Container sx={{ mt: 5 }} maxWidth="md">
            <StyledPaper>
                {isTheDate ? <Slideshow/> : <Countdown targetDate={theDateAsDate}/>}
            </StyledPaper>
        </Container>
    );
}

export default App;


