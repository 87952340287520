import { Container, Grid, makeStyles, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/system";
import { styled } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import { Button } from '@mui/material';

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: "#120f21",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fffc02',
    fontFamily: "math"
  }));

export function Slideshow() {

    // const classes = useStyles();

    let messages: string[] = [
        "Avaa lahja!",
        /* "Hyvää syntymäpäivää Maiski!" */
    ];


    let [msgIndex, setMsgIndex] = useState<number>(0);

    let onClick = () => {
        setMsgIndex(++msgIndex/*  % messages.length */);
        if(msgIndex === messages.length) {

            (window as any).startSnowFlakes("30");
        }
    }

    let fadeEasing = {
        enter: 4000, exit: 4000
    };

    return (
        <>
            <Stack alignItems="center"
                display="flex"
                style={{'background': "#120f21"}}
                justifyContent="center">
                <Item hidden={msgIndex >= messages.length}>
                    <Item hidden={msgIndex % 2 != 0}>
                        <Fade timeout={fadeEasing}in={true}>
                            <div>
                                <Typography align="center" variant="h1" component="h2">
                                Avaa lahja!
                                </Typography>
                                <Item>
                                    <Button variant="contained"
                                        onClick={onClick}>
                                        Klikkaa
                                    </Button>
                                </Item>
                            </div>
                        </Fade>
                    </Item>
                </Item>
                <Item hidden={msgIndex < messages.length}>
                    <Fade timeout={fadeEasing} in={msgIndex % 2 != 0}>
                        <div>
                        <Typography align="center" variant="h1" component="h2">
                            Hyvää syntymä&shy;päivää Anu!
                        </Typography>
                        <Typography align="center" variant="h4" component="h2">
                            Liput on varattu Cats musikaaliin Nokia Areenaalla 29.10.2022 klo 19:00.
                        </Typography>
                        <Typography align="center" variant="h5" component="h2">
                            Ja sitä ennen ravintolaan. 😋
                        </Typography>
                            <img width="100%" alt="cats"
                                src={`/photos/cats_orig.jpg`}
                            />
                        </div>
                    </Fade>
                </Item>
            </Stack>
        </>
    );
}